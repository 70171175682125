<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "SejarahOrionPenjaminanIndonesia",

  metaInfo: { title: "Sejarah Orion Penjaminan Indonesia" },

  extends: View,

  mixins: [LoadSections(["hero-alt", "about-us-detail", "info"])],

  props: {
    id: {
      type: String,
      default: "tentang-kami",
    },
  },
};
</script>
